@import "../main/abstracts/index";
.section--social-embed{
    background-color: $color__bg-light;
    // padding-top: 80px;
    // padding-bottom: 80px;
    position: relative;
    header{
        margin-bottom: 32px!important;
        @include media-breakpoint-down(md) {
            h2{ 
                text-align: left;
            }
        }
    }
} 